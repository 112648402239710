<template>
    <div class="page-content">
      <div class="block-content-wrapper">
        <div class="block-title" v-if="postTitle !== null && !isError">
            <span class="back">Back to  
              <router-link class="link-back" :to="{ path: '/technical-blog'}">
                Technical Blog
              </router-link>
            </span>
            <h1> 
              <span class="title-icon"></span>
              {{ postTitle }} 
            </h1>
        </div>
        <pulse-loader class="spinner" :loading="!postTitle && !isError" :color="color" :size="size"></pulse-loader>
        <div class="container" v-if="postTitle !== null && !isError">
          <div class="html-page" v-html="postHtml">
          </div>
        </div>
        <div class="error-text" v-if="isError">
          <object :data="errorSvg" type="image/svg+xml">
          </object>
          <span>Something went wrong and post can't be displayed</span><br/>
          <span>Try again after a while</span><br/>
          <span>Back to  
            <router-link class="link-back" :to="{ path: '/technical-blog'}">
              Technical Blog Page
            </router-link>
          </span>
        </div>
      </div>
    </div>
</template>

<script>

import { api } from '@/ghost-settings';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
    name: 'Blog',
    components: {
      PulseLoader
    },
    data() {
      return {
        slug: this.$route.params.slug,
        page_title: 'Blog',
        postHtml: null,
        postTitle: null,
        isError: false,
        errorSvg: "warning.svg"
      }
    },
    mounted () {
      const svgPath = require.context(`@/assets/`, false);
      this.errorSvg = svgPath(`./${this.errorSvg}`);
    },
    methods: {
      getPost() {
        api.posts.read({slug: this.slug})
        .then((post) => {
            this.postHtml = post.html;
            this.postTitle = post.title;
        })
        .catch(() => {
            this.isError = true;
        });
      }
    },
    created() {
      this.getPost();
    }
}
</script>

<style scoped lang="scss">
.page-content {
  min-width: 280px;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 80px);
  background-image: $gradient;
  position: relative;
  padding: 6rem 3rem;
  box-sizing: border-box;
  .block-content-wrapper {
    max-width: 1200px;
    width: 100%;
    height: 100%;
  }
}
.dark-bg {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
.container {
  width: 100%;
  height: 100%;
  padding: 3rem;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 20px;
  position: relative;
  z-index: 3;
}
.block-title {
  display: flex;
  flex-direction: column;
  color: $light-main;
  position: relative;
  z-index: 3;
  text-align: left;
  margin-bottom: 3rem;
  .back {
    color: $dark-white;
    margin-bottom: 2rem;
    margin-left: 3rem;
    font-size: 14px;
  }
   h1 {
    text-align: start;
    font-size: 1.5em;
    text-transform: uppercase;
    letter-spacing: 3px;
    display: flex;
    font-weight: 100;
    margin: 0;
    span {
        display: inline-block;
    }
  }
  .title-icon::before {
    width: 3rem;
    display: block;
    content: "\1405";
    text-align: left;
    font-size: 1.5rem;
  }
}
.html-page {
  text-align: left;
  height: 100%;
  width: 80%;
  max-width: 750px;
  margin: auto;
}
.html-page ::v-deep pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  background: black;
  color: white;
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
}
.html-page ::v-deep img {
  width: 100%;
  margin: 30px auto;
  display: block;
}
.html-page ::v-deep iframe {
  width: 100%;
}
.html-page ::v-deep a {
  word-break: break-all;
}
.error-text {
  color: $dark-white;
  object {
    height: 200px;
    fill: $dark-white;
    display: flex;
    margin: auto;
    margin-bottom: 40px;
  }
}
.link-back {
  color: $light-main;
  margin-left: 5px;
}
.spinner {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 660px) {
  .page-content {
    padding: 3rem 1.5rem;
    .title-icon::before {
        display: none;
    }
    .block-title .back {
      margin-left: 0;
    }
    h1 {
      font-size: 1.2rem;
    }
  }
  .container {
    margin: 0;
    width: 100%;
    padding: 1.5rem;
  }
  .html-page {
    width: 100%;
    &::v-deep h2 {
      font-size: 20px;
      margin: 20px 0;
    }
    &::v-deep p {
      font-size: 14px;
    }
    &::v-deep figure {
      margin: 0;
    }
  }
}
</style>